import React from 'react'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeOne from "../../components/layout/sidemenu/sidemenu_se_one"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit1Icon from '../../images/icons/unit_01_icon.svg'
import forcingResponse22 from '../../images/student-pages/unit-1/22_forcing_response.gif'
import solarRadiation23 from '../../images/student-pages/unit-1/23_solar_radiation_plot.gif'
import treesChanging24 from '../../images/student-pages/unit-1/24_trees_changing_colors.jpg'
import geeseFlying25 from '../../images/student-pages/unit-1/25_geese_flying.jpg'
import abundantGases28 from '../../images/student-pages/unit-1/28_abundant_atmospheric_gases.gif'


const CarbonForcings13 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 1.3 Carbon Forcing" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeOne location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'1.3 Carbon Forcing'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit1Icon }
                iconAlt="Unit 1 icon"
                studentPageHeader="Unit 1: Carbon &amp; Climate in the Past"
                unitNumber="1.3"
                studentPageHeaderSub="Carbon Forcing"
                sectionA={ <>A Forcing Brainstorm</> }
                sectionALink="/unit-1/1.3-carbon-forcing/#a-forcing-brainstorm"
                sectionB={ <>Forcings in Systems</> }
                sectionBLink="/unit-1/1.3-carbon-forcing/#forcings-in-system"
                sectionC={ <>Greenhouse Gases</> }
                sectionCLink="/unit-1/1.3-carbon-forcing/#greenhouse-gases"
                sectionD={ <>Climate Models</> }
                sectionDLink="/unit-1/1.3-carbon-forcing/#climate-models"
                sectionE={ <>Reflect and Connect</> }
                sectionELink="/unit-1/1.3-carbon-forcing/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>What gases in the atmosphere connect to Earth's temperature and climate?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image21.childImageSharp.fluid}
                          alt="Earth's atmosphere"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight rounded ml-2 img-fluid d-none d-md-block"
                      fluid={props.data.image21.childImageSharp.fluid}
                      alt="Earth's atmosphere"
                    />

                    <p>In Lesson 1.2, you saw the relationship between changes in CO<sub>2</sub> levels in Earth's atmosphere and temperature variations at Antarctica. The two variables increased and decreased together. You saw that the timing of those changes matched some other changes on Earth, such as the growth and decay of large ice sheets.</p>
                  </>
                }

                paragraphTwo={
                  <>
                    <p>But from these changes, can you tell what factor actually <em>caused</em> the change in the system? Understanding the cause of changes in systems is part of answering basic scientific questions. However, those causes are not always obvious. In this lesson, you will start to see how scientists combine measurements with models to better understand factors that may affect Earth's climate. In particular, you will learn that:</p>
                    <ul>
                      <li>Forcings lead to responses or changes in systems.</li>
                      <li>Forcings in the Earth system can cause Earth's climate to change.</li>
                      <li>Greenhouse gases affect the temperature of Earth's atmosphere.</li>
                      <li>Models and simulations can help you understand forcings in Earth's climate system.</li>
                    </ul>
                    Use the focus question to guide your thinking throughout this lesson.
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="a-forcing-brainstorm" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">A Forcing Brainstorm</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        You've probably been "forced" to do a task by your parent or older sister: maybe it was homework, chores, practicing sports, or riding in the middle seat of the car. When you are forced to do something, it causes a certain outcome, or response, even if it is not right away.
                      </p>

                      <p>
                        For step 1, get together with two partners for 2 to 3 minutes. Think creatively about examples from your home, school, or community to include in the Force/Response table. Get ready to share your best ideas with the class. The table has an example to get you started.
                      </p>

                      <ol>
                        <li>
                          Review the three important words in the header of the table. Copy the table into your science notebook. Add your own examples to the table that you and your partners think of. <br />
                          <strong>Forcing:</strong> A <em>forcing</em> is any event or thing that causes or leads to change. <br />
                          <strong>Response:</strong> The <em>response</em> is the result or change from the forcing. <br />
                          <strong>Lag:</strong> The <em>lag</em> is how long after the forcing that the response starts to occur.
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover studentTable">
                        <thead>
                          <tr>
                            <th className="table-active" scope="col">Forcing</th>
                            <th className="table-active" scope="col">Response</th>
                            <th className="table-active" scope="col">Lag</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mom tells me, "Clean your room!"</td>
                            <td>I clean my room.</td>
                            <td>I do it 3 days later.</td>
                          </tr>
                          <tr>
                            <td>Add your own examples here&hellip;</td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="forcings-in-system" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Forcings in Systems</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Earth's climate also responds to forcings. As you just saw, a <strong>forcing</strong> is an <strong>input</strong> that causes a change in a system. From the forcing, the system has a response, or an output. Think of heating a kettle of water on the stove, as in the figure. The input, or forcing, is thermal energy from the stove. The top graph shows when the stove is on or off. The response, or output, is the temperature changes in the water in the kettle. The lag is the time it takes for the response to occur. For this system, the response builds over time. Thus, the lag is not the time for the total response to occur. Here, the full response would be a kettle with boiling water.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3 imageBorder"
                            src={ forcingResponse22 }
                            alt="Forcing response chart"
                          />
                        </Col>
                      </Row>
                      <img
                        className="smallImageRight imageBorder rounded ml-2 mb-1 img-fluid d-none d-md-block"
                        src={ forcingResponse22 }
                        alt="Forcing response chart"
                      />

                      <p>
                        Answer the following questions about the forcings, responses, and lags in systems.
                      </p>

                      <ol start="2">
                        <li>Compare the shape of the two Response graphs. How does the change in the setting of the knob for the stove compare with the change in water temperature?</li>
                        <li>
                          Imagine a rock in the desert sitting all day in the summer sun. After sunset, how long do you think it might take to cool to the temperature of the night air? Use the example of the tea kettle to draw your answer with two graphs that show:
                          <ol type="a">
                            <li>The amount of sunlight on the rock (y-axis) as the sun sets and it becomes night (x-axis)</li>
                            <li>The relative temperature of the rock (y-axis) as the sun sets and it becomes night (x-axis)</li>
                          </ol>
                        </li>
                        <li>Look at an example of a forcing and the response in a seasonal climate. The Solar Radiation graph shows solar radiation coming to Denver, Colorado (green curve). The graph also shows the average temperatures by day. Answer the following questions to understand the relationship between solar radiation and temperature in Denver. </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="largeImageCenter rounded img-fluid mb-3"
                            src={ solarRadiation23 }
                            alt="Denver, Colorado, solar radiation"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter rounded img-fluid d-none d-md-block mb-4"
                        src={ solarRadiation23 }
                        alt="Denver, Colorado, solar radiation"
                      />

                      <ol type="a">
                        <li>What variable is on the x-axis? How long is the period shown?</li>
                        <li>What variable is on the left y-axis? What is the range of values for this variable?</li>
                        <li>What variable is on the right y-axis? What is the range of values for this variable?</li>
                        <li>
                          Complete these sentences about the graph: <br />
                          <em>The <strong>forcing</strong> in the system is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                          <em>The peaks and valleys for the <strong>forcing</strong> are in the months &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; and &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                          <em>The <strong>response</strong> of the system is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                          <em>The peaks and valleys for the <strong>response</strong> are in the months &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; and &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em>
                        </li>
                        <li>Does the response occur right away, or after a lag? Use specific examples of dates and time in your answer.</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="greenhouse-gases" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: Correlations and Causes</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">
                        Correlations and Causes
                      </p>

                      <p>
                        Think back to the CO<sub>2</sub> and temperature data from Antarctica. These two variables increased and decreased together. When one variable changed, the other changed by a similar amount. You would say that these two variables correlate. A <strong>correlation</strong> is where two factors or variables increase or decrease together, both in direction and relative amount. It does not indicate why the factors changed, however; a cause tells you that. A <strong>cause</strong> is where one factor in a system forces one or more other variables to change.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ treesChanging24 }
                            alt="trees changing colors"
                          />
                        </Col>
                      </Row>
                      <img
                        className="verticalImageRight rounded ml-2 mb-1 img-fluid d-none d-md-block"
                        src={ treesChanging24 }
                        alt="trees changing colors"
                      />

                      <p>
                        Scientists need to carefully test both correlations and causes. This may seem obvious, but it is not always clear in Earth systems. This is because parts of the Earth system can change together (for example, atmosphere, oceans, biosphere, and geosphere). Think back to the correlation you saw in Lesson 1.2 between CO<sub>2</sub> and temperature. This correlation does not necessarily mean that one <em>caused</em> the other. For example, something else might be making each of these two variables change. Scientists have to test for a cause, and they've done these tests. In laboratories, they have measured the kinds of radiation that CO<sub>2</sub> and other gases of the air absorb. When the scientists begin the radiation, CO<sub>2</sub> molecules in air absorb radiation. The temperature increases for that air. This is how they can test between correlations and causes with CO<sub>2</sub>.
                      </p>

                      <p>
                        Here is another example of cause and correlation. In North America in the fall, decreasing solar radiation and cold weather cause some trees to go dormant. Just before the trees drop their leaves, pigment changes in the leaves <em>cause</em> the leaves to change color. Similarly, the cold weather also reduces or eliminates food sources for some woodland birds. As a result, they migrate south to regions with more food. Therefore, leaves changing color <em>correlates</em> with fall migration; they don't happen together.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ geeseFlying25 }
                            alt="Geese flying"
                          />
                        </Col>
                      </Row>
                      <img
                        className="verticalImageLeft rounded mr-3 mb-1 img-fluid d-none d-md-block"
                        src={ geeseFlying25 }
                        alt="Geese flying"
                      />

                      <p>
                        But is one of these a <em>cause</em>? It wouldn't make much sense to claim that migrating birds cause leaves to change color or drop. Leaves changing color wouldn't cause birds to migrate, even if it is an indicator to the birds that winter is coming. The change in seasonal climate and light <em>cause</em> several effects to occur together. Colder seasonal temperatures kill food sources for many birds. Separately, decreasing light causes and cold weather causes trees to lose leaves and go dormant.
                      </p>

                      <p>
                        Read the following examples. Discuss with a partner or your class which factors are causes, and which factors merely correlate. Write your ideas in your science notebook.
                      </p>

                      <ol>
                        <li>You bought and downloaded four new songs. At the same time, the account balance on your music card went down, and you have less money to buy more songs.</li>
                        <li>It was a good day for the stock market: Prices increased for shares in high-tech companies, as well as manufacturing. This occurred just after a positive report from the government about the amount of new jobs creation. Which change here could cause the other two to change?</li>
                        <li>The physician observed these symptoms in the sick student: fever, runny nose, and aches and pains.</li>
                        <li>The car passenger had several injuries from the accident: a broken humerus and collar bone, and a crushed foot.</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="greenhouse-gases" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">Greenhouse Gases</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image26.childImageSharp.fluid}
                            alt="Greenhouse"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight rounded ml-3 mb-2 img-fluid d-none d-md-block"
                        fluid={props.data.image26.childImageSharp.fluid}
                        alt="Greenhouse"
                      />

                      <p>
                        Maybe you have heard of the <strong>greenhouse effect</strong>. The greenhouse effect is the warming of Earth's atmosphere from the absorption of energy given off by Earth. That energy initially comes from heat from the Sun. The effect is similar to the warming in a greenhouse with a glass ceiling. Here is how this model works.
                      </p>

                      <p>
                        Sunlight shines in and warms the ground and plants in the greenhouse. The warmed ground and plants in turn warm the air immediately around them. Because the air is trapped in the greenhouse, the air is warmer than the air outside the greenhouse. The greenhouse is a pretty good model if you compare the warmed, trapped air in the greenhouse with all the warmed air of Earth's atmosphere. Without a ceiling, the warmed air in the greenhouse would rise and move away from the plants. Then you would not notice a difference compared with the air temperature outside. So, a limitation of the greenhouse model is that it does not quite work without a ceiling to hold in the warmed air.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image27.childImageSharp.fluid}
                            alt="Natural greenhouse effect"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageLeft rounded mr-3 mb-3 img-fluid d-none d-md-block"
                        fluid={props.data.image27.childImageSharp.fluid}
                        alt="Natural greenhouse effect"
                      />

                      <p>
                        The greenhouse effect is vital for Earth, as represented in the diagram. Without a natural greenhouse effect, Earth's atmosphere would be about 33&deg;C (60&deg;F) colder and most of earth's water would be ice. Earth would be too cold for most plants and animals.
                      </p>

                      <p>
                        The greenhouse effect is caused by certain molecules in the atmosphere, called greenhouse gases, or GHGs for short. A <strong>greenhouse gas</strong> is a molecule that absorbs the energy given off by Earth's surface or by the atmosphere itself. That energy originally comes from solar radiation that enters the Earth system. The greenhouse gases cause the atmosphere to "trap" energy that would otherwise leave Earth and escape into space. Thus, greenhouse gases in the air are a forcing that leads to warming of the atmosphere and of the surface of Earth.
                      </p>

                      <p>
                        Greenhouse gases include water (H<sub>2</sub>0), carbon dioxide (CO<sub>2</sub>), methane (CH<sub>4</sub>), nitrous oxide (N<sub>2</sub>O), and ozone (O<sub>3</sub>). Look at the atomic diagrams for these gases. They have a central atom about which the other atoms vibrate and rotate. In contrast, most of the atmosphere is nitrogen (N<sub>2</sub>) and oxygen (O<sub>2</sub>) gas. These gases do not have a central atom. They do not absorb energy given off by the Earth's surface or the atmosphere, so they do not contribute significantly to additional warming of the atmosphere.
                      </p>

                      <p>
                        Water (H<sub>2</sub>O) contributes the most of any greenhouse gas toward the greenhouse effect on Earth. It is found in the atmosphere as water vapor and as fine droplets in clouds. Scientists estimate that water as vapor and clouds lead to about 75 percent of greenhouse warming on Earth. Water vapor is part of the water cycle. When the water vapor in the air increases beyond 2 to 4 percent, the H<sub>2</sub>O condenses and leaves the air as precipitation. Thus, the H<sub>2</sub>O content of air does not increase beyond certain limits.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3 imageBorder"
                            src={ abundantGases28 }
                            alt="Abundant atmospheric gases"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter imageBorder rounded img-fluid d-none d-md-block mb-4"
                        src={ abundantGases28 }
                        alt="Abundant atmospheric gases"
                      />

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image29.childImageSharp.fluid}
                            alt="Greenhouse gas forcings on earth's climate"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="smallImageRight imageBorder rounded ml-2 mb-1 img-fluid d-none d-md-block"
                        fluid={props.data.image29.childImageSharp.fluid}
                        alt="Greenhouse gas forcings on earth's climate"
                      />

                      <p>
                        Carbon dioxide (CO<sub>2</sub>) is the second-most abundant greenhouse gas. Overall, CO<sub>2</sub> contributes to about 20 percent of the total greenhouse warming on Earth. Yet, unlike H<sub>2</sub>O, humans are adding CO<sub>2</sub> to air largely from the use of fossil fuels (such as coal, petroleum, and natural gas). <strong>Fossil fuels</strong> are the remains of plants and animals that existed millions of years ago. The carbon from these organisms was trapped in the Earth and, over time, became fossil fuels. Unlike H<sub>2</sub>O vapor, CO<sub>2</sub> cannot just "rain" out of the sky when it gets to a certain level. The main way that CO<sub>2</sub> can be removed from the atmosphere is through the carbon cycle. Currently, however, the carbon cycle can only remove about half of the CO<sub>2</sub> added by humans. The result is that CO<sub>2</sub> levels are increasing. You will investigate this further in Unit 2.
                      </p>

                      <p>
                        Other greenhouse gases lead to a small amount of warming, about 5 percent of the total. The graph above is a good way to show the total effect of greenhouse warming. Imagine a change that equals a temperature rise of about 1&deg;C. Scientists have shown that the initial, direct warming from CO<sub>2</sub> is about 0.2&deg;C, with other minor GHGs contributing about 0.05&deg;C. That total is about 0.25&deg;C, or 25 percent, of the 1&deg;C of warming. Water and clouds provide an additional warming of about 0.75&deg;C, or about 75 percent of the 1&deg;C.
                      </p>

                      <p>
                        To summarize, note a few things that are represented in the Greenhouse Effect diagrams:
                      </p>

                      <ul>
                        <li>The natural greenhouse effect leads to an atmosphere that is warmer than it would be otherwise (left).</li>
                        <li>The human-enhanced greenhouse effect results from the additional CO<sub>2</sub> and other greenhouse gases added by humans (right).</li>
                        <li>The same amount of solar energy enters the Earth system from the Sun, yet the extra greenhouse gases slow the transfer of energy from Earth back to space.</li>
                      </ul>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image30.childImageSharp.fluid}
                            alt="Natural vs human-enhanced greenhouse effect"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter rounded img-fluid d-none d-md-block mb-3"
                        fluid={props.data.image30.childImageSharp.fluid}
                        alt="Natural vs human-enhanced greenhouse effect"
                      />

                      <p>
                        It can be difficult to understand how some gases in the atmosphere absorb energy, while other gases do not. To help you, your teacher will do a demonstration. The energy you will use is from a microwave. Microwaves produce radiation that you cannot see. This is similar to the type of energy that is radiated from Earth to the atmosphere.
                      </p>

                      <ol start="5">
                        <li>
                          Your teacher will have two beakers (#1 and #2) with fluid at room temperature. Your teacher will put both beakers in the microwave, and turn it on for 20 seconds.
                          <ol type="a">
                            <li>Describe what happens in each beaker. Does the temperature of the beakers feel the same or different? </li>
                            <li>For each beaker, which molecules in the atmosphere could it represent?</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="climate-models" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Climate Models</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        When scientists work to answer important questions, they like to gather different types of evidence. The different types of evidence are like different clues that a detective might use to solve a mystery. In a similar way, different approaches and lines of evidence have helped scientists understand the relationship between greenhouse gases and Earth's temperature. For example, one approach is to look at the geologic past and analyze the relationship between GHGs and temperatures. You did this in Unit 1 with CO<sub>2</sub> and temperature from the Antarctica ice core. A second approach is to compare measurements of GHGs and temperature over decades. You will do this in Unit 3. Third, they can study other planets with greenhouse gas levels very different from Earth; for example, Venus is sweltering hot beneath thick layers of GHGs.
                      </p>

                      <p>
                        A fourth approach is to use computer models to test <em>forcings</em> and <em>responses</em> in systems. You will do this with a real climate model in this lesson. NASA scientists developed the CO<sub>2</sub> Spike Experiment model to explore forcings and responses of Earth's climate. Models like this let you study real situations, or test what could happen if the system got pushed more than normal.
                      </p>

                      <div className="col-12">
                        <iframe
                          className="embed-container w-100"
                          src="https://media.bscs.org/carbonconnections/interactives/spike-experiment/spike-experiment.html"
                          title="Spike experiment interactive"
                        />
                      </div>
                      <p>If you are unable to see the interactive, click <a href="https://media.bscs.org/carbonconnections/interactives/spike-experiment/spike-experiment.html" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a> to open it in a new tab.</p>

                      <p>
                        In particular, the CO<sub>2</sub> Spike Experiment shows a "spike" of a sudden forcing. For example, what if the CO<sub>2</sub> levels in the atmosphere suddenly increased from the low values of an ice age, to the high values of an interglacial period? This would be a spike in CO<sub>2</sub> of the air from about 180 to 280 ppm. The Climate Model lets you model spikes that are more or less than this.
                      </p>

                      <ol start="6">
                        <li>
                          View the results of the simulation with a partner and answer the following questions.
                          <ol type="a">
                            <li>What are the units for the x-axis?</li>
                            <li>What year does the CO<sub>2</sub> spike occur? For how many years does the simulation run?</li>
                            <li>One responding curve is Earth's <strong>temperature</strong>. What color is it?</li>
                            <li>
                              Compared with the spike, describe the shape of the temperature response. <br />
                              <em>Hint: A way to describe it would be to estimate how long it takes to stop changing.</em>
                            </li>
                          </ol>
                        </li>
                        <li>
                          Two other response curves are shown for <strong>ice cover </strong>of oceans and <strong>water vapor</strong> in the atmosphere.
                          <ol type="a">
                            <li>What color are these responding curves?</li>
                            <li>After the CO<sub>2</sub> spike, does ice cover increase or decrease? Does this match the result you observed from temperature?</li>
                            <li>After the CO<sub>2</sub> spike, does water vapor in the atmosphere increase or decrease?</li>
                          </ol>
                        </li>
                        <li>Scientists can also run simulations with negative spikes. Imagine a simulation with a spike from 280 to 180 ppm CO<sub>2</sub>. What do you think would happen to <strong>temperature</strong>, <strong>ice cover </strong>of oceans, and <strong>water vapor</strong> in the atmosphere?</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        E. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Summarize some ideas from this lesson with the following questions:
                      </p>

                      <ol start="9">
                        <li>
                          In the Earth system, some events are a strong forcing, like the "spike" simulation. For example, imagine a volcanic eruption that fills the air around it with ash and gas. Do you think that this might result in cooling or warming of the atmosphere? <br />
                          <em>You are not expected to know this answer, but it may help you think about forcings in systems. You'll learn more about this forcing in Unit 3.</em>
                        </li>
                        <li>In the simulation, you saw that it took a while for temperature, water vapor, and ice cover to "catch up" after the CO<sub>2</sub> spike. The total response took about 80 years. What is the significance of this for factors that could change the CO<sub>2</sub> levels of Earth's atmosphere?</li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop />

              <ButtonNext
                ButtonText="1.4: Global Connections"
                NextLink="/unit-1/1.4-global-connections/"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default CarbonForcings13

export const query = graphql`
  query {
    image21: file(relativePath: { eq: "student-pages/unit-1/21_earth_atmosphere.jpg" }) {
      ...rowImage
    }
    image24: file(relativePath: { eq: "student-pages/unit-1/24_trees_changing_colors.jpg" }) {
      ...rowImage
    }
    image25: file(relativePath: { eq: "student-pages/unit-1/25_geese_flying.jpg" }) {
      ...rowImage
    }
    image26: file(relativePath: { eq: "student-pages/unit-1/26_greenhouse.jpg" }) {
      ...rowImage
    }
    image27: file(relativePath: { eq: "student-pages/unit-1/27_natural_greenhouse_effect.jpg" }) {
      ...rowImage
    }
    image29: file(relativePath: { eq: "student-pages/unit-1/29_greenhouse_gas_forcings.png" }) {
      ...rowImage
    }
    image30: file(relativePath: { eq: "student-pages/unit-1/30_greenhouse_effect_humans_natural.jpg" }) {
      ...rowImage
    }
  }
`
